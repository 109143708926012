import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import contract from './contracts/Grimweepers.json';
import logo from './img/grim-weepers-logo.png';
import gridweepers from './img/grid-weepers.png';
import gate from './img/gw-gate.png';
import tee from './img/gw-tee.png';
import { ethers } from 'ethers';

const contractAddress = '0x5ECD385aA0591f4F45ac75E7b7c7a2375e124fCE';
const abi = contract;
const opensea = 'https://testnets.opensea.io/assets/'+contractAddress+'/';

function App() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentChain, setCurrentChain] = useState(null);
  const [currentTokensMeta, setCurrentTokensMeta] = useState([]);
  const [baseTokenURI, setBaseTokenURI] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [amt, setAmt] = useState(1);
  const [mintPrice, setMintPrice] = useState(0);
  const [goLive, setGoLive] = useState(false);
  const NETWORK = '5777';

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      //console.log("Make sure you have Metamask installed!");
      return;
    } else {
      //console.log("Wallet exists! We're ready to go!")
      
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    
      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });

    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      const chainID = window.ethereum.networkVersion;
      
      setCurrentAccount(account);
      setCurrentChain(chainID);

      if (chainID === NETWORK){  
             
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        let tokens = await nftContract.tokensOfOwner(account);
        let BTURI = await nftContract.baseTokenURI();
        let PBTURI = await nftContract.preRevealBaseURI();      

        if(tokens.length === 0){
          return;
        }

        if(BTURI || PBTURI){

          if(BTURI){
            BTURI = BTURI.replace(':', '');
          }

          if(PBTURI && !BTURI){
            BTURI = PBTURI.replace(':', '');
          }

          setBaseTokenURI(BTURI)

          let tokensMeta = [];
          for(let i = 0 ; i < tokens.length; i++){
            let tokenId = tokens[i].toString();
            let tokenURI = await nftContract.tokenURI(tokenId);

            fetch("https://ipfs.io/"+tokenURI.replace(':/', ''))
            .then(res => res.json())
            .then(
              (result) => {
                tokensMeta.push({token_id: tokenId, data: result});
                if(i === (tokens.length - 1)){
                  setCurrentTokensMeta(tokensMeta);
                };
              },
              (error) => {
                console.log(error);
              }
            );
          }
        }else{
          let tokensMeta = [];
          for(let i = 0 ; i < tokens.length; i++){
            let tokenId = tokens[i].toString();
            tokensMeta.push({data:{'edition' : tokenId, 'name': 'Grim Weepers #'+tokenId}});
            if(i === (tokens.length - 1)){
              setCurrentTokensMeta(tokensMeta);
            };
          }
        }


      }else{
        // try {
        //   let chainid = ethers.utils.hexStripZeros(ethers.utils.hexlify(4));

        //   await window.ethereum.request({
        //     method: 'wallet_switchEthereumChain',
        //     params: [{ chainId: chainid }],
        //   });

        // } catch (err) {
        //   console.log(err.code);
        // }
      }

    } else {
      //console.log("No authorized account found");
      setCurrentAccount(null);
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
      checkWalletIsConnected();
    } catch (err) {
      console.log(err)
    }
  }

  async function setMintCost(amt){
    try {
      const { ethereum } = window;
      
        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          const price = ethers.utils.formatEther(await nftContract.PRICE()).toString();

          setAmt(amt);
          
          let payment = (amt * price).toString();

          setMintPrice(payment);

        } else {
          console.log("Ethereum object does not exist");
        }

    } catch (err) {
      console.log(err);

    }
  }

  async function mintNftHandler(amt){
    try {
      const { ethereum } = window;
      
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const price = ethers.utils.formatEther(await nftContract.PRICE()).toString();
        
        console.log("Initialize payment");
        let payment = (amt * price).toString();
        setMintPrice(payment);

        let nftTxn = await nftContract.mintNFTs(amt, { value: ethers.utils.parseEther(payment) });

        setModalMessage('Mining... please wait');
        console.log("Mining... please wait");
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

        setModalMessage(null);
        checkWalletIsConnected();

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);

      if(err.error){
        let errMessage = err.error.message;
        if(err.error.code === -32603){
          errMessage = errMessage.replace('execution reverted: ', '');
        }else if(err.error.code === -32000){
          errMessage = 'Insufficient Funds';
        }

        setErrorMessage(errMessage);

        setTimeout(function(){
          setErrorMessage(null);
        }, 5000)
      }

    }
  }

  async function buryWeeper(id) {
    try {
      const { ethereum } = window;
      
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        let ids = [];
        ids.push(id);

        let nftTxn = await nftContract.toggleBuried(ids);
        await nftTxn.wati();
        console.log(nftTxn);
        
      }
    } catch (err) {
      console.log(err);

    }
  }

  const whiteListButton = () => {
    return (
      <div className='d-flex align-items-center flex-column my-4 my-md-0'>
          <a href='https://twitter.com/grimweepersnft' target='_blank' rel='noreferrer' className='btn btn-connect'>
            <i className='fa-brands fa-twitter'></i> Follow
          </a>
        </div>   
    )
  }

  const connectWalletButton = () => {
    return (
        <div className='d-flex align-items-center flex-column my-4 my-md-0'>
          <button onClick={connectWalletHandler} className='btn btn-connect'>
            Connect Wallet
          </button>
        </div>   
    )
  }

  const mintNftButton = () => {
    
    const handleSubmit= (e) => {
      if(amt <= 0){
        setErrorMessage('Choose Amount To Mint');
        setTimeout(function(){
          setErrorMessage(null);
        }, 5000)
      }else{
        mintNftHandler(amt)
      }
      e.preventDefault();
    }

    return (
      <div className={currentChain === NETWORK ? '' : 'd-none'}>
          <div className='d-flex flex-column align-items-md-center text-md-center py-4'>
            <h3>How Many Do You Want To Mint?</h3>
            <form onSubmit={e => { handleSubmit(e) }} className="d-flex align-items-md-center flex-column flex-md-row mt-3">
                <div className='amt'>
                  <label className={amt === 1 ? 'checkcontainer checked' : 'checkcontainer'}>
                    <input type="radio" name="radio" value="1" onChange={e => setMintCost(e.target.value)}/>
                    <span className="radioval">1</span>
                    <span className="radiobtn"></span>
                  </label>
                  <label className="checkcontainer">
                    <input type="radio" name="radio" value="2" onChange={e => setMintCost(e.target.value)}/>
                    <span className="radioval">2</span>
                    <span className="radiobtn"></span>
                  </label>
                  <label className="checkcontainer">
                    <input type="radio" name="radio" value="3" onChange={e => setMintCost(e.target.value)}/>
                    <span className="radioval">3</span>
                    <span className="radiobtn"></span>
                  </label>
                  <label className="checkcontainer">
                    <input type="radio" name="radio" value="4" onChange={e => setMintCost(e.target.value)}/>
                    <span className="radioval">4</span>
                    <span className="radiobtn"></span>
                  </label>
                  <label className="checkcontainer">
                    <input type="radio" name="radio" value="5" onChange={e => setMintCost(e.target.value)}/>
                    <span className="radioval">5</span>
                    <span className="radiobtn"></span>
                  </label>
                </div>
                <div className='mint-btn-wrapper'>
                  <button type="submit" className="btn btn-mint">
                    Mint
                  </button>
                  <span className='accountId btn disabled mt-3 mt-md-0'>{currentAccount.substring(0,6)}...{currentAccount.slice(-4)}</span>
                  <span className='btn disabled mint-price mt-3 mt-md-0'>{mintPrice} eth</span>
                </div>
            </form>
            <div id="error-message" className={errorMessage ? 'show' : ''}>{errorMessage}</div>
          </div>

      </div>
    )
  }

  const hiddenTokens = () => {
    return (
      <div id='nft-container'>
        {currentTokensMeta.map(item => (
          <div key={item.data.edition} className="nft-token">
            <figure className="prereveal ratio ratio-1x1"></figure>
            <p className='mt-3'><strong>{item.data.name}</strong></p>
          </div>
        ))}
      </div>
    )
  }
  
  const realTokens = () => {

    const handleClick= (id) => {
      buryWeeper(id);
    }

    return (
      <div id='nft-container'>
        {currentTokensMeta.map(item => (
          <div key={item.data.edition} className="nft-token">
            <a href={opensea+item.token_id} className="image-link" target='_blank' rel='noreferrer'>
              <img src={item.data.image} className="img-fluid" alt={item.data.description}/>
            </a>
            <p className='mt-3'><strong>{item.data.name}</strong></p>
            <button className='btn btn-bury' onClick={(e) => handleClick(item.data.edition, e)}>Bury Weeper</button>
            <div className='attribute-holder d-none'>
              {item.data.attributes.map(att => (
                <div key={att.trait_type} className="attribute">
                  <span><strong>{att.trait_type}</strong></span>
                  <span>{att.value}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const yourTokens = () => {
    return (
      <section className='sec-black'>
        <div className='container-skinny scroll-sm'>
          <div className="side-by-side py-md-4">  
            <div className='d-flex flex-column px-md-3'>
              <h1>Your Tokens</h1>
            </div>
            <div className='d-flex flex-column px-md-3'>
              {baseTokenURI ? realTokens() : hiddenTokens()}
            </div>
          </div>
        </div> 
      </section>
    )
  }

  const wrongChain = () => {
    return (
      <section className='sec-black'>
        <div className='container-skinny text-center'>
          <h1>{currentAccount ? 'Wrong Network' : ''}</h1>
          </div>
      </section>
    )
  }

  const footer = () => {
    return (
      <footer>
        <div className='container-skinny'>
          <div className='footer-links'>
            <a href='https://twitter.com/grimweepersnft' target='_blank' rel='noreferrer'>
              <i className='fa-brands fa-twitter'></i>
            </a>
            <a href='https://takeoutorder.co' target='_blank' rel='noreferrer'>
              <i className="fa-solid fa-shirt"></i>
            </a>
          </div>
        </div>
      </footer>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
    setMintCost(amt);
    setGoLive(false);
  }, [amt])

  return (
    <div className='main-app'>

      <div className={modalMessage ? 'show modal-wrapper' : 'modal-wrapper'}>
        <div className='modal-message'>
          <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
          {modalMessage}
        </div>
      </div>

      <div id='hero' className='col-12 text-center'>
            <img src={logo} className='logo' alt="grim weepers logo"/>
      </div>

      <section className='sec-black'>
        <div className='container-skinny'>
          {goLive && currentAccount ? mintNftButton() : null}
          {goLive && !currentAccount ? connectWalletButton() : null}
          {!goLive ? whiteListButton() : null}
          <div className='mt-4 text-center d-none'>
          <span className='contract-link'>Contract: <a href='https://rinkeby.etherscan.io/address/0x120673Ed75509863Da278Eb2c5F0cbd7B8047320'>
          0x120673Ed75509863Da278Eb2c5F0cbd7B8047320
          </a></span>
          </div>
        </div>
      </section>

      {goLive && currentAccount && currentChain !== NETWORK ? wrongChain() : null}
      {goLive && currentAccount && currentChain === NETWORK && currentTokensMeta.length > 0 ? yourTokens() : null}

      <section className='sec-black'>
        <div className='container-skinny'>
          <div className="side-by-side align-items-center py-md-5">
            <div className='d-flex flex-column px-md-5'>
              <figure className='main-img'><img src={gridweepers} alt='grim weepers' className='img-fluid' /></figure>
            </div>
            <div className='d-flex flex-column px-md-5'>
              <h1>Who Could Use A Good Cry?</h1>
              <p className="py-3">Grim Weepers are a group of 10,000 sad reaper PFPs that endure a surprisingly abundant pool of lonely traits. Generally Weepers like to share in their misery, but some though rare, aren't as lucky as others.</p>
              
            </div>
          </div>
        </div>
      </section>

      <section className='sec-black'>
        <div className='container-thin'>
          <div className="side-by-side align-items-center py-5">
            <div className='d-flex flex-column px-md-5 order-md-2'>
              <div className='collage-1'>
                <figure className='secondary-img border-round'><img src={tee} alt='grim weeper' className='img-fluid' /></figure>
                <figure className='main-img border-round'><img src={gate} alt='grim weeper' className='img-fluid' /></figure>
              </div>
            </div>
            <div className='d-flex flex-column px-md-5 order-md-1'>
              <h1>A Utility Inspired Art Collection</h1>
              <p className="py-3">A Weeper doesn't just pair well with a shoulder to cry on, it also unlocks the gates to the private Discord where you'll gain access to meetups, events, discounts and exclusive drops from <a href='https://takeoutorder.co' target='_blank' rel='noreferrer'>Takeout Order</a>.</p>
            </div>
          </div>
        </div>
      </section>
      {footer()}
    </div>
  )
}

export default App;
